var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":"comissionStatus","rules":{
    required: _vm.requiredField,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.labelName,"label-for":"comissionStatus","label-class":_vm.labelClass}},[_c('v-select',{class:errors.length > 0 ? 'error-select' : '',attrs:{"id":"comissionStatus","options":_vm.commissionStatusOptions,"placeholder":'Status da Comissão',"disabled":_vm.readonly,"clearable":!_vm.readonly},scopedSlots:_vm._u([{key:"option",fn:function(ref){
  var label = ref.label;
  var color = ref.color;
return [_c('b-badge',{staticClass:"text-capitalize",style:({'background-color': color, 'min-width': '110px' }),attrs:{"pill":""}},[_vm._v(" "+_vm._s(label)+" ")])]}},{key:"selected-option",fn:function(ref){
  var label = ref.label;
  var color = ref.color;
return [_c('b-badge',{staticClass:"text-capitalize",style:({'background-color': color, 'min-width': '110px' }),attrs:{"pill":""}},[_vm._v(" "+_vm._s(label)+" ")])]}}],null,true),model:{value:(_vm.comissionStatus),callback:function ($$v) {_vm.comissionStatus=$$v},expression:"comissionStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }