<template>
  <validation-provider
    #default="{ errors }"
    name="comissionStatus"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      :label="labelName"
      label-for="comissionStatus"
      :label-class="labelClass"
    >
      <v-select
        id="comissionStatus"
        v-model="comissionStatus"
        :class="errors.length > 0 ? 'error-select' : ''"
        :options="commissionStatusOptions"
        :placeholder="'Status da Comissão'"
        :disabled="readonly"
        :clearable="!readonly"
      >
        <template #option="{ label, color }">
          <b-badge
            pill
            class="text-capitalize"
            :style="{'background-color': color, 'min-width': '110px' }"
          >
            {{ label }}
          </b-badge>
        </template>

        <template #selected-option="{ label, color }">
          <b-badge
            pill
            class="text-capitalize"
            :style="{'background-color': color, 'min-width': '110px' }"
          >
            {{ label }}
          </b-badge>
        </template>

      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup,
  BBadge,
} from 'bootstrap-vue'

import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BBadge,
    ValidationProvider,
    vSelect,
  },
  model: {
    prop: 'comissionStatusValue',
    event: 'comissionStatusChange',
  },
  props: {
    comissionStatusValue: {
      type: [Object, Boolean],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelName: {
      type: String,
      required: false,
      default: 'Status da Comissão',
    },
    labelClass: {
      type: [String, Array],
      required: false,
      default: '',
    },
    visibleStatus: {
      type: Array,
      required: false,
      default() {
        return ['pending', 'released', 'payed']
      },
    },
  },
  data() {
    let commissionStatusOptions = [
      {
        label: 'Liberado',
        value: 'released',
        color: '#C8C6CE',
      },
      {
        label: 'Pendente',
        value: 'pending',
        color: '#FC7033',
      },
      {
        label: 'Pago',
        value: 'payed',
        color: '#55AF0F',
      },
    ]

    commissionStatusOptions = commissionStatusOptions.filter(status => this.visibleStatus.includes(status.value))

    return {
      commissionStatusOptions,
    }
  },
  computed: {
    comissionStatus: {
      get() {
        return this.comissionStatusValue
      },
      set(value) {
        const valueMap = {
          released: {
            label: 'Liberado',
            value: 'released',
            color: '#C8C6CE',
          },
          pending: {
            label: 'Pendente',
            value: 'pending',
            color: '#FC7033',
          },
          payed: {
            label: 'Pago',
            value: 'payed',
            color: '#55AF0F',
          },
        }

        this.$emit('comissionStatusChange', valueMap[value] || value)
      },
    },
  },
  watch: {
    comissionStatusValue(newVal) {
      this.comissionStatus = newVal
    },
  },
  created() {
    this.$emit('ready', true)
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select {
  background-color: #fff;
}

.vs__search {
  font-size: 1rem !important;
}
</style>
