<template>
  <div>
    <validation-provider
      v-slot="{ errors }"
      :name="`${fieldName}`"
      :rules="{
        required: requiredField,
      }"
    >
      <b-form-group
        label-for="product_price"
        :label="label"
        :label-class="labelClass"
      >
        <b-input-group
          :prepend="labelPrepend"
          :append="labelAppend"
          :class="[readonly ? 'input-readonly': '', errors.length > 0 ? 'is-invalid' : '']"
        >
          <cleave
            id="field_generic"
            v-model="data"
            name="field_generic"
            :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
            :options="option ? option : price"
            placeholder="0,00"
            :readonly="readonly"
            @blur="adjustItemDecimalPlaces()"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BInputGroup,
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import Cleave from 'vue-cleave-component'

export default {
  components: {
    BInputGroup,
    BFormGroup,
    ValidationProvider,
    Cleave,
  },
  model: {
    prop: 'dataValue',
    event: 'dataChange',
  },
  props: {
    dataValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    option: {
      type: Object,
      required: true,
      default: null,
    },
    places: {
      type: Number,
      required: true,
      default: 0,
    },
    fieldName: {
      type: String,
      required: false,
      default: 'field_generic',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    labelClass: {
      type: [Array, String],
      required: false,
      default: '',
    },
    labelPrepend: {
      type: String,
      required: false,
      default: 'R$',
    },
    labelAppend: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    const price = {
      numeral: true,
      numeralDecimalScale: 2,
      numeralDecimalMark: ',',
      delimiter: '.',
      numeralPositiveOnly: true,
    }

    return {
      price,
      required,
    }
  },
  computed: {
    data: {
      get() {
        if (this.dataValue) {
          return this.dataValue.toString().replace('.', ',')
        }
        return null
      },
      set(value) {
        this.$emit('dataChange', value)
      },
    },
  },
  created() {
    this.$emit('ready', true)
  },
  methods: {
    adjustItemDecimalPlaces() {
      const num = this.data

      if (num !== '' && num !== null) {
        const result = parseFloat(num.toString().replace('.', '').replace(',', '.')).toFixed(this.places)
        this.data = result
      }

      this.changeCleave()
    },
    changeCleave() {
      this.$emit('change')
    },
  },
}
</script>
