<template>
  <div>
    <div v-if="!isLoaded">
      <b-card
        no-body
      >
        <b-card-title class="m-0">
          <b-col
            cols="12"
            md="12"
            class="py-1"
          >
            <spin-loader />
          </b-col>
        </b-card-title>
      </b-card>
    </div>
    <div
      v-if="isLoaded"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-card
            no-body
          >
            <b-card-title class="m-0">
              <div>
                <b-row
                  class="px-2 d-flex justify-content-center"
                >
                  <b-col
                    cols="12"
                    md="2"
                    class="d-flex py-1"
                  >
                    <b-avatar
                      :src="userImage"
                      size="5rem"
                      rounded
                    />
                    <div
                      class="ml-2 user_info"
                    >
                      <h2
                        class="font-weight-bold"
                      >
                        {{ user.get_full_name }}
                      </h2>
                      <span
                        class="font-weight-regular"
                      >
                        <template v-if="user.permission_set">
                          {{ user.permission_set.name }}
                        </template>
                        <template v-else>
                          -
                        </template>
                      </span>
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="py-1 d-flex align-items-center"
                  >
                    <b-form-group
                      label="Início da Data de Pagamento"
                      label-for="period_start"
                      :label-class="'formLabel'"
                      class="w-100"
                    >
                      <b-form-datepicker
                        id="date_gte"
                        v-model="commissionFilter.real_pay_date__gte"
                        :required="false"
                        locale="pt-BR"
                        :class="'form-control'"
                        size="md"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        label-no-date-selected="Nenhuma data foi selecionada"
                        selected-variant="primary"
                        placeholder="Selecione uma data"
                        label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                        reset-button
                        reset-button-variant="info"
                        label-reset-button="Limpar"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="py-1 d-flex align-items-center"
                  >
                    <b-form-group
                      label="Término da Data de Pagamento"
                      label-for="period_finish"
                      :label-class="'formLabel'"
                      class="w-100"
                    >
                      <b-form-datepicker
                        id="date_lte"
                        v-model="commissionFilter.real_pay_date__lte"
                        :required="false"
                        locale="pt-BR"
                        :class="'form-control'"
                        size="md"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        label-no-date-selected="Nenhuma data foi selecionada"
                        selected-variant="primary"
                        placeholder="Selecione uma data"
                        label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                        reset-button
                        reset-button-variant="info"
                        label-reset-button="Limpar"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="py-1 d-flex align-items-center"
                  >
                    <commission-status-select
                      v-model="commissionFilter.status"
                      :label-name="'Status'"
                      :label-class="'formLabel'"
                      :visible-status="statusList"
                      class="w-100"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="d-flex justify-content-end"
                  >
                    <div
                      class="commission_values px-1 pending_values"
                    >
                      <div
                        class="bar"
                      />
                      <div
                        class="values py-2"
                      >
                        <span
                          class="valueLabel"
                        >
                          Comissão Pendente
                        </span>
                        <span
                          v-if="isLoadedTotal"
                          class="valueCurrency"
                        >
                          R$ {{ userValues.total_pending | formatCurrency() }}
                        </span>
                        <b-spinner
                          v-else
                          variant="info"
                          type="grow"
                          small
                          class="align-middle my-1"
                        />
                      </div>
                    </div>
                    <div
                      class="commission_values px-1 paid_values"
                    >
                      <div
                        class="bar"
                      />
                      <div
                        class="values py-2"
                      >
                        <span
                          class="valueLabel"
                        >
                          Comissão Paga
                        </span>
                        <span
                          v-if="isLoadedTotal"
                          class="valueCurrency"
                        >
                          R$ {{ userValues.total_paid | formatCurrency() }}
                        </span>
                        <b-spinner
                          v-else
                          variant="info"
                          type="grow"
                          small
                          class="align-middle my-1"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-title>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BAvatar,
  BFormGroup,
  BFormDatepicker,
  BSpinner,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import syslic from '@/syslic'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SyncLoad from '@/components/utils/syncLoad'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import CommissionStatusSelect from '@/components/commission/CommissionStatusSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BAvatar,
    BFormGroup,
    BFormDatepicker,
    BSpinner,
    SpinLoader,
    CommissionStatusSelect,
  },
  filters: {
    formatCurrency,
  },
  props: {
  },
  data() {
    const profile = syslic.authentication.getProfile()
    const isLoaded = false

    const userImage = ''
    const userId = router.currentRoute.params.id
    const user = ''

    const filterEmpty = {
      real_pay_date__lte: null,
      real_pay_date__gte: null,
      status: null,
    }

    const commissionFilter = JSON.parse(JSON.stringify(filterEmpty))

    const userValues = {}

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('avatar')
    syncLoad.addTrigger('profile')
    syncLoad.addTrigger('values')

    syncLoad.onReady(() => {
      this.isLoaded = true
      this.$emit('ready', true)
    })

    const isLoadedTotal = false

    const fetchId = ''

    return {
      profile,
      isLoaded,
      userImage,
      userId,
      user,
      filterEmpty,
      commissionFilter,
      userValues,
      syncLoad,
      isLoadedTotal,
      fetchId,
    }
  },
  computed: {
    statusList() {
      let statusList = ['pending', 'released', 'payed']

      if (!this.hasPermissionToUpdate && !this.hasPermissionToRead) {
        statusList = ['pending', 'payed']
      }

      return statusList
    },
    hasPermissionToUpdate() {
      return this.profile.get_permissions_for_modules.commission.can_edit
    },
    hasPermissionToRead() {
      return this.profile.get_permissions_for_modules.commission.can_read
    },
  },
  watch: {
    commissionFilter: {
      handler: function updateFilter() {
        this.emitFilter(this.commissionFilter)
      },
      deep: true,
    },
    'commissionFilter.real_pay_date__lte': {
      handler: function updateFilter(val) {
        this.date_lte = val
        this.updateUserValues()
      },
    },
    'commissionFilter.real_pay_date__gte': {
      handler: function updateFilter(val) {
        this.date_gte = val
        this.updateUserValues()
      },
    },
    'commissionFilter.status': {
      handler: function updateFilter() {
        this.updateUserValues()
      },
    },
  },
  created() {
    this.getUserProfile(this.userId)
    this.getUserAvatar(this.userId)
    this.getUserValues(this.userId)
  },
  methods: {
    validateDateRange(startDate, endDate, message) {
      if (startDate && endDate) {
        if (startDate > endDate) {
          this.$swal({
            title: 'Atenção!',
            text: message,
            icon: 'warning',
            showConfirmButton: false,
            timer: 4000,
          })
        }
        return false
      }
      return true
    },
    checkDate() {
      let valid = true

      const {
        real_pay_date__gte, real_pay_date__lte,
      } = this.commissionFilter

      valid = this.validateDateRange(
        real_pay_date__gte,
        real_pay_date__lte,
        'A data de término de pagamento não pode ser anterior a data de início.',
      )

      return valid
    },
    getUserAvatar(id) {
      syslic
        .user
        .getUserImage(id)
        .then(response => {
          this.userImage = response
        })
      this.syncLoad.ready('avatar')
    },
    getUserProfile(id) {
      syslic
        .user
        .fetchUserBasic(id)
        .then(response => {
          this.user = response.data
          this.syncLoad.ready('profile')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar dados do usuário.',
              text: 'Não foi possível carregar os dados do usuário, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    getUserValues(id) {
      this.isLoadedTotal = false
      syslic
        .commission
        .user
        .fetchUserValues(id)
        .then(response => {
          this.isLoadedTotal = true
          this.userValues = response.data
          this.syncLoad.ready('values')
        })
        .catch(() => {
          this.isLoadedTotal = true
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar valores de comissões do usuário.',
              text: 'Não foi possível carregar os valores de comissões do usuário, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    updateUserValues() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      this.isLoadedTotal = false

      this.checkDate()

      const params = {
        date_gte: this.commissionFilter.real_pay_date__gte ? this.commissionFilter.real_pay_date__gte : null,
        date_lte: this.commissionFilter.real_pay_date__lte ? this.commissionFilter.real_pay_date__lte : null,
      }

      syslic
        .commission
        .user
        .fetchUserValues(this.userId, params)
        .then(response => {
          if (fetchPending === this.fetchId) {
            this.userValues = response.data
            this.isLoadedTotal = true
          }
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao atualizar valores de comissões do usuário.',
                text: 'Não foi possível atualizar os valores de comissões do usuário, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.isLoadedTotal = true
          }
        })
    },
    emitFilter(val) {
      const filter = {
        real_pay_date__gte: val.real_pay_date__gte ? val.real_pay_date__gte : null,
        real_pay_date__lte: val.real_pay_date__lte ? val.real_pay_date__lte : null,
        status: val.status ? val.status.value : null,
      }

      this.$emit('filter-change', filter)
    },
  },
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/base/core/colors/palette-variables.scss";
.user_info {
  > h2 {
    color: $primary;
    font-size: 1.2rem;
  }

  > span {
    font-size: 1.1rem;
    color: #5e5873 !important;
  }
}

.formLabel {
  font-weight: bolder;
}

.spin-loader-block {
  position: relative !important;
  top: 0% !important;
}

.commission_values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  .bar {
    min-height: 100%;
    width: 4.5rem;
  }

  .values {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    gap: .8rem;

    .valueLabel {
      font-size: 1rem;
      color: #5e5873 !important;
    }

    .valueCurrency {
      font-weight: bolder;
      font-size: 1.5rem;
      color: #5e5873 !important;
    }
  }

}

.paid_values {
  > .bar {
    background-color: #55AF0F;
  }
}

.pending_values {
  > .bar {
    background-color: #FC7033;
  }
}
</style>
