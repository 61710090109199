<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div
      v-show="isLoaded"
    >
      <b-row
        class="justify-content-center"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <commission-header
            ref="headerComponent"
            @filter-change="updateFilter"
            @ready="syncLoad.ready('header')"
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <b-card no-body>
            <b-card-body
              class="p-0"
            >
              <invoicing-list
                ref="invoicingComponent"
                :filter="filter"
                :user="routerProfile"
                @check-invoicing="onCheckInvoicing"
                @uncheck-invoicing="onUncheckInvoicing"
                @store-unsaved="onStoreUnsaved"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="hasPermissionToUpdate"
        class="justify-content-center"
      >
        <b-col
          cols="12"
          md="6"
          lg="6"
          class="d-flex justify-content-start"
        >
          <b-button
            class="d-flex align-items-center justify-content-center pendingBtn"
            variant="transparent"
            :style="{'min-width': '15rem'}"
            :disabled="selectedInvoicing.length === 0"
            @click="showConfirmOrCancelDelete('pending')"
          >
            <svg-icon
              class="mr-50"
              type="mdi"
              :path="mdiCashClock"
            />
            <span>Marcar Pendente</span>
          </b-button>
          <b-button
            class="d-flex align-items-center justify-content-center ml-1"
            variant="outline-info"
            :style="{'min-width': '15rem'}"
            :disabled="selectedInvoicing.length === 0"
            @click="showConfirmOrCancelDelete('released')"
          >
            <svg-icon
              class="mr-50"
              type="mdi"
              :path="mdiCashRefund"
            />
            <span>Estornar</span>
          </b-button>
          <b-button
            class="d-flex align-items-center justify-content-center ml-1 payedBtn"
            variant="transparent"
            :style="{'min-width': '15rem'}"
            :disabled="selectedInvoicing.length === 0"
            @click="showConfirmOrCancelDelete('payed')"
          >
            <svg-icon
              class="mr-50"
              type="mdi"
              :path="mdiCashCheck"
            />
            <span>Pagar</span>
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
          class="d-flex justify-content-end"
        >
          <b-button
            class="ml-1 d-flex align-items-center justify-content-center"
            variant="primary"
            :style="{'min-width': '12rem'}"
            @click="saveUnsaved"
          >
            Salvar
          </b-button>
          <b-button
            class="ml-1 d-flex align-items-center justify-content-center"
            variant="outline-primary"
            :style="{'min-width': '12rem'}"
            :to="{ name: 'commission'}"
          >
            Voltar
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-modal
        id="alertResultModal"
        ref="alertResultModal"
        class="modalStyle"
        no-close-on-backdrop
        no-close-on-esc
        centered
        hide-footer
      >
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalUnsuccess"
              >
                <svg-icon
                  type="mdi"
                  size="70"
                  :style="{'color': modalIconColor}"
                  :path="modalIcon"
                />
              </span>
              <span
                class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
              >
                {{ modalText }}
              </span>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BButton,
  BCol,
  BCard,
  BForm,
  BCardBody,
} from 'bootstrap-vue'

import {
  mdiCheckCircle,
  mdiAlertCircle,
  mdiCashPlus,
  mdiCashCheck,
  mdiCashClock,
  mdiCashRefund,
  mdiContentSaveCheckOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import SvgIcon from '@jamescoyle/vue-icon'
import router from '@/router'
import syslic from '@/syslic'
import CommissionHeader from './CommissionHeader.vue'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SyncLoad from '@/components/utils/syncLoad'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import InvoicingList from './components/InvoicingList.vue'

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BCard,
    BForm,
    BCardBody,
    SvgIcon,
    CommissionHeader,
    SpinLoader,
    InvoicingList,
  },

  data() {
    const profile = syslic.authentication.getProfile()
    const routerProfile = {}

    const selectedInvoicing = []
    const commissionsPercentage = []
    const unsavedCommissions = new Set()

    const modalIconColor = ''
    const modalIcon = ''
    const modalText = ''

    const modalSettingsMap = {
      mixed: {
        color: '#FF7E07',
        icon: mdiAlertCircle,
        text: 'Não é possível alterar comissões com status diferentes, por favor, selecione apenas comissões com o mesmo status e tente novamente.',
      },
      payed: {
        color: '#049F14',
        icon: mdiCheckCircle,
        text: 'Itens PAGOS com sucesso!',
      },
      pending: {
        color: '#FF7E07',
        icon: mdiCashClock,
        text: 'Itens marcados como PENDENTES com sucesso!',
      },
      released: {
        color: '#049F14',
        icon: mdiCashRefund,
        text: 'Itens ESTORNADOS com sucesso!',
      },
      saved: {
        color: '#005C96',
        icon: mdiContentSaveCheckOutline,
        text: 'Itens SALVOS com sucesso!',
      },
    }

    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('verify_permissions')
    syncLoad.addTrigger('header')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    const filter = {
      page_size: 10,
      search: '',
      user: router.currentRoute.params.id,
      status: '',
      real_pay_date__lte: null,
      real_pay_date__gte: null,
    }

    return {
      profile,
      routerProfile,
      selectedInvoicing,
      commissionsPercentage,
      unsavedCommissions,
      modalIconColor,
      modalIcon,
      modalText,
      modalSettingsMap,
      isLoaded,
      syncLoad,
      filter,
    }
  },
  beforeRouteLeave(to, from, next) {
    const text = 'Existem itens não salvos, deseja prosseguir?'
    if (this.unsavedCommissions.size > 0) {
      this.$bvModal
        .msgBoxConfirm(text, {
          id: 'confirmation_box',
          title: 'Voltar',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          contentClass: 'text-center font-weight-bold',
          footerClass: 'py-2 justify-content-center',
          bodyClass: 'py-2',
        })
        .then(value => {
          if (value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  computed: {
    hasPermissionToUpdate() {
      return this.profile.get_permissions_for_modules.commission.can_edit
    },
    hasPermissionToRead() {
      return this.profile.get_permissions_for_modules.commission.can_read
    },
  },
  watch: {
  },
  created() {
    this.verifyIsOwnerCommission()
    this.getRouterProfile(router.currentRoute.params.id)
  },
  methods: {
    verifyIsOwnerCommission() {
      if (router.currentRoute.params.id === this.profile.get_uuid || this.hasPermissionToUpdate || this.hasPermissionToRead) {
        this.syncLoad.ready('verify_permissions')
      } else {
        router.push({
          name: 'commission-detail',
          params: {
            id: this.profile.get_uuid,
          },
        })
      }
    },
    updateFilter(val) {
      this.filter.status_commission = val.status
      this.filter.real_pay_date__lte = val.real_pay_date__lte
      this.filter.real_pay_date__gte = val.real_pay_date__gte

      this.$refs.invoicingComponent.updateFilter(this.filter)
    },
    onCheckInvoicing(val, percentage) {
      this.selectedInvoicing.push(val)
      this.commissionsPercentage[val.id] = percentage
    },
    onUncheckInvoicing(val) {
      const index = this.selectedInvoicing.indexOf(val)
      if (index > -1) {
        this.selectedInvoicing.splice(index, 1)
      }

      const percentageIndex = this.commissionsPercentage.indexOf(this.commissionsPercentage[val.id])
      if (percentageIndex > -1) {
        this.commissionsPercentage.splice(percentageIndex, 1)
      }
    },
    onStoreUnsaved(invoicing, value, text) {
      let newValue = true
      this.unsavedCommissions.forEach(itemData => {
        if (itemData.item === invoicing) {
          itemData.percentage = value // eslint-disable-line no-param-reassign
          itemData.notes = text // eslint-disable-line no-param-reassign
          newValue = false
        }
      })
      if (newValue) {
        this.unsavedCommissions.add(
          {
            item: invoicing,
            percentage: value,
            notes: text,
          },
        )
      }
    },
    showConfirmOrCancelDelete(status) {
      const statusTextMap = {
        payed: 'Deseja confirmar o pagamento dos itens selecionados?',
        pending: 'Deseja marcar os itens selecionados como pendentes? Eles serão exibidos para o usuário correspondente.',
        released: 'Deseja estornar os itens selecionados? Eles voltarão para o status de liberado.',
      }

      this.$bvModal
        .msgBoxConfirm(statusTextMap[status], {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          contentClass: 'text-center font-weight-bold',
          footerClass: 'py-1 justify-content-center',
          bodyClass: 'py-2',
        })
        .then(value => {
          if (value) {
            this.checkMixedInvoicing(status)
          }
        })
    },
    checkMixedInvoicing(status) {
      const allCommissions = []
      const allStatus = new Set()
      let haveNewCommission = false

      this.selectedInvoicing.forEach(invoicing => {
        if (invoicing.commission_set) {
          let haveCommission = false

          invoicing.commission_set.forEach(commission => {
            const userId = router.currentRoute.params.id
            if (userId === commission.owner_set.get_uuid) {
              haveCommission = true
              allStatus.add(commission.status)
              allCommissions.push(() => this.updateUserCommission(commission, status))
            }
          })

          if (!haveCommission) {
            allCommissions.push(() => this.createUserCommission(invoicing, status))
            haveNewCommission = true
          }
        }
      })

      if (
        ((haveNewCommission && allStatus.size > 1) || allStatus.size > 1)
        || (haveNewCommission && allStatus.size === 1 && !allStatus.has('released'))
      ) {
        this.selectedInvoicing = []
        this.$refs.invoicingComponent.clearChecks()
        this.changeModalSettings('mixed')
        this.$refs.alertResultModal.show()
        return
      }

      this.changeModalSettings(status)
      this.executeCommissions(allCommissions)
    },
    updateUserCommission(commission, newStatus, percentage, text) {
      const data = {
        owner: commission.owner,
        invoicing: commission.invoicing,
        status: newStatus,
        commission_percentage: commission.commission_percentage,
        notes: text,
      }

      if (percentage) {
        data.commission_percentage = percentage
      }

      if (this.commissionsPercentage[commission.invoicing]) {
        data.commission_percentage = this.commissionsPercentage[commission.invoicing]
      }

      return new Promise((resolve, reject) => {
        syslic
          .commission
          .updateCommission(commission.id, data)
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    createUserCommission(invoicing, newStatus, percentage, text) {
      const pageUser = this.routerProfile

      const data = {
        invoicing: invoicing.id,
        owner: pageUser.id,
        owner_set: pageUser,
        commission_percentage: pageUser.commission,
        status: newStatus,
        notes: text,
      }

      if (percentage) {
        data.commission_percentage = percentage
      }

      if (this.commissionsPercentage[invoicing.id]) {
        data.commission_percentage = this.commissionsPercentage[invoicing.id]
      }

      return new Promise((resolve, reject) => {
        syslic
          .commission
          .addCommission(data)
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    deleteUserCommission(id) {
      return new Promise((resolve, reject) => {
        syslic
          .commission
          .deleteCommission(id)
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    async executeCommissions(commissions) {
      Promise.all(commissions.map(fun => fun()))
        .then(() => {
          this.$refs.alertResultModal.show()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar as comissões.',
              text: 'Não foi possível atualizar as comissões, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
        .finally(() => {
          this.selectedInvoicing = []
          this.commissionsPercentage = []
          this.unsavedCommissions = new Set()
          this.$refs.headerComponent.updateUserValues()
          this.$refs.invoicingComponent.clearChecks()
          this.$refs.invoicingComponent.clearPercentages()
          this.$refs.invoicingComponent.fetchData()
        })
    },
    async saveUnsaved() {
      const commissionsToSave = []

      if (this.unsavedCommissions.size === 0) {
        this.$swal({
          title: 'Atenção',
          text: 'Todos os itens estão atualizados, não há nada para salvar.',
          icon: 'info',
          showConfirmButton: false,
          timer: 4000,
        })
        return
      }

      const tempUnsaved = Array.from(this.unsavedCommissions)

      const invoicingCommissionsPromises = tempUnsaved.map(async itemData => {
        const invoicingCommissions = await this.fetchInvoicingCommissions(itemData.item.id)

        if (invoicingCommissions.commission_set.length > 0) {
          const existentCommission = invoicingCommissions.commission_set.find(commission => commission.owner_set.get_uuid === this.routerProfile.get_uuid)
          if (existentCommission) {
            commissionsToSave.push(() => this.updateUserCommission(existentCommission, existentCommission.status, itemData.percentage, itemData.notes))
          } else {
            commissionsToSave.push(() => this.createUserCommission(itemData.item, 'released', itemData.percentage, itemData.notes))
          }
        } else {
          commissionsToSave.push(() => this.createUserCommission(itemData.item, 'released', itemData.percentage, itemData.notes))
        }
      })

      await Promise.all(invoicingCommissionsPromises)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao salvar os itens',
              text: 'Não foi possível atualizar os itens, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })

      if (commissionsToSave.length > 0) {
        this.changeModalSettings('saved')
        this.executeCommissions(commissionsToSave)
        this.unsavedCommissions = new Set()
      } else {
        this.$swal({
          title: 'Atenção',
          text: 'Todos os itens estão atualizados, não há nada para salvar.',
          icon: 'info',
          showConfirmButton: false,
          timer: 4000,
        })
      }
    },
    changeModalSettings(setup) {
      this.modalIconColor = this.modalSettingsMap[setup].color
      this.modalIcon = this.modalSettingsMap[setup].icon
      this.modalText = this.modalSettingsMap[setup].text
    },
    getRouterProfile(id) {
      syslic
        .user
        .fetchUserBasic(id)
        .then(response => {
          this.routerProfile = response.data
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar dados do usuário.',
              text: 'Não foi possível carregar os dados do usuário, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    fetchInvoicingCommissions(id) {
      return new Promise((resolve, reject) => {
        const filter = {
          user: this.filter.user,
          status: this.filter.status,
        }

        syslic
          .commission
          .fetchCommissionsFromInvoicingId(id, filter)
          .then(response => {
            resolve(response.data)
          })
          .catch(() => {
            reject()
          })
      })
    },
  },
  setup() {
    const toast = useToast()
    return {
      toast,
      mdiCheckCircle,
      mdiAlertCircle,
      mdiCashPlus,
      mdiCashCheck,
      mdiCashClock,
      mdiCashRefund,
      mdiContentSaveCheckOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.commissionIconTitle {
  width: 21px;
  height: 21px;
}

.commissionTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.commissionTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.commissionTab .nav-link h4 {
  color: #6e6b7b;
  font-size: 1rem;
}

.commissionTab .nav-link.active {
  background-color: #e2edf3;
}

.commissionTab .nav-link.active h4 {
  color: $primary;
}

.commissionTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}
 .commissionTab .card-body {
  padding-left: 1.0rem;
  padding-right: 1.0rem;
 }

 .commissionTab .tab-content {
   margin-top: -1rem;
 }

 .pendingBtn {
  background-color: #FC7033 !important;
  outline: none !important;
  border: none !important;
  color: $white !important;

  &:hover {
    box-shadow: 0 .4rem .8rem #FC703385 !important;
  }
 }

 .payedBtn {
  background-color: #55AF0F !important;
  outline: none !important;
  border: none !important;
  color: $white !important;

  &:hover {
    box-shadow: 0 .4rem .8rem #55AF0F85 !important;
  }
 }

 .modalStyle .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;

  justify-content: center;

  padding-bottom: 40px;
}

.modal-content {
  > .modal-body {
    font-size: 1.2rem !important;
  }
}
</style>
