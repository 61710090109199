<template>
  <div>
    <div class="mr-2 ml-2 mt-1 mb-1">
      <b-row>
        <b-col
          cols="12"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div>
            <b-form-group
              label="Exibir"
              label-for="idSearchBox"
              class="mb-0"
            >
              <v-select
                id="idSearchBox"
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
              >
                <span slot="no-options">Não há opções para essa quantidade.</span>
              </v-select>
            </b-form-group>
          </div>
          <b-form-input
            v-model.lazy="searchQuery"
            placeholder="Buscar"
            type="text"
            class="d-inline-block mt-2 ml-1"
          />
        </b-col>
      </b-row>
    </div>
    <div>
      <b-table
        class="position-relative biddingTableView"
        primary-key="id"
        responsive
        show-empty
        striped
        hover
        empty-text="Não existem dados para o filtro selecionado."
        :fields="tableColumns"
        :items="invoicingTable"
        :sort-by.sync="sortOptions.sortBy"
        :sort-desc.sync="sortOptions.isSortDirDesc"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner
              variant="info"
              class="align-middle mb-1"
            />
            <br>
            <strong class="pt-2">Carregando...</strong>
          </div>
        </template>
        <template #cell(commitment__order__contract__bidding__client__name)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ field.item.client_set.name | truncate(40, '...') }}
          </div>
        </template>

        <template #cell(note_number)="field">
          <div
            class="d-block text-nowrap font-weight-bolder"
          >
            {{ field.item.note_number }}
          </div>
        </template>

        <template #cell(delivery__invoicing_delivery_date)="field">
          <div
            v-if="field.item.invoicing_delivery_date"
            class="d-block text-nowrap"
          >
            {{ moment(field.item.invoicing_delivery_date).format('DD/MM/YYYY') }}
          </div>
          <div
            v-else
            class="d-block text-nowrap"
          >
            -
          </div>

        </template>

        <template #cell(real_pay_date)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ moment(field.item.real_pay_date).format('DD/MM/YYYY') }}
          </div>
        </template>

        <template #cell(delayed_days)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ field.item.delayed_days }} {{ field.item.delayed_days == 1 ? 'dia' : 'dias' }}
          </div>
        </template>

        <template #cell(total_value)="field">
          <div
            class="d-block text-nowrap"
          >
            R$ {{ field.item.total_items | formatCurrency() }}
          </div>
        </template>

        <template #cell(liquid_margin)="field">
          <div
            v-if="field.item.liquid_margin"
            class="d-block text-nowrap"
          >
            {{ field.item.liquid_margin | formatCurrency() }} %
          </div>
          <div
            v-else
            v-b-tooltip.hover
            class="d-block text-nowrap"
            title="Não é possível calcular a margem liquida, pois ainda nao existe um frete contratado na entrega relacionada a este faturamento."
          >
            -
          </div>
        </template>

        <template #cell(commission_percentage)="field">
          <b-form-group
            v-if="isCommissionEditable(field.item.commission_set) && hasPermissionToUpdate"
            label-for="price"
            class="custom-margin"
          >
            <cleave-customized
              id="price"
              v-model="commissionPercentage[field.item.id]"
              :option="options.price"
              :places="2"
              :label-prepend="''"
              :label-append="'%'"
              @change="storeCommissionValues(field.item.id, commissionPercentage[field.item.id], commissionNotes[field.item.id])"
            />
          </b-form-group>
          <div
            v-else
            class="d-block text-nowrap font-weight-bold"
          >
            {{ commissionPercentage[field.item.id] }} %
          </div>

        </template>

        <template #cell(value)="field">
          <div
            class="d-block text-nowrap"
          >
            R$ {{ finalValue[field.item.id] }}
          </div>
        </template>

        <template #cell(status)="field">
          <div
            class="d-block text-nowrap"
          >
            <!--
            -->
            <b-badge
              pill
              class="text-capitalize sizeMin px-1 d-inline-flex align-items-center justify-content-center"
              :style="{'background-color': getStatus(field.item.commission_set, 'color')}"
            >
              {{ getStatus(field.item.commission_set, 'name') }}
            </b-badge>
          </div>
        </template>

        <template #head(actions)>
          <div
            v-if="hasPermissionToUpdate"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-checkbox
              id="selectAllCheckbox"
              v-model="selectAll"
              name="checkbox-all-select"
              @input="selectAllCheckbox"
            />
          </div>
        </template>

        <template
          #cell(actions)="field"
        >
          <div
            class="actionsColumn d-flex align-items-center justify-content-center"
          >
            <b-button
              class="btn-icon rounded-circle mr-50"
              variant="flat-info"
              size="md"
              @click="openCommissionNotesModal(field.item)"
            >
              <svg-icon
                size="18"
                type="mdi"
                :path="mdiPen"
              />
            </b-button>

            <b-form-checkbox
              v-if="hasPermissionToUpdate"
              v-model="checkedInvoicing[field.item.id]"
              name="checkbox-select"
              @input="checkboxChange(field.item)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-if="visibleFields.length !== 0"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="idCommissionNotesModal"
        ref="CommissionNotesModal"
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        centered
        hide-footer
      >
        <b-form
          @submit.prevent="storeCommissionValues(noteId, commissionPercentage[noteId], commissionNotes[noteId])"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Observações"
                label-for="commission_notes"
                :label-class="'notesLabel'"
              >
                <b-form-textarea
                  id="commission_notes"
                  v-model="commissionNotes[noteId]"
                  class="mt-1"
                  type="text"
                  rows="5"
                  :placeholder="!hasPermissionToUpdate || !noteEditable ? 'Nenhuma Observação' : 'Insira suas observações'"
                  :disabled="!hasPermissionToUpdate || !noteEditable"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            class="py-1"
          >
            <b-col
              cols="12"
              md="12"
              class="d-flex justify-content-end"
            >
              <b-button
                v-if="hasPermissionToUpdate && noteEditable"
                id="saveCommissionNotes"
                class="d-flex justify-content-center align-items-center noteBtn mr-1"
                variant="primary"
                type="submit"
              >
                Salvar
              </b-button>
              <b-button
                id="closeCommissionNotes"
                class="d-flex justify-content-center align-items-center noteBtn"
                :variant="hasPermissionToUpdate && noteEditable ? 'outline-primary' : 'primary'"
                @click="closeCommissionNotesModal()"
              >
                Voltar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BPagination,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BBadge,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPen,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import vSelect from 'vue-select'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'
import CleaveCustomized from '@/components/utils/CleaveCustomized.vue'
import formatCurrency from '@/components/utils/filters/formatCurrency'

export default {
  components: {
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BPagination,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BBadge,
    BButton,
    CleaveCustomized,
    SvgIcon,
    vSelect,
  },
  filters: {
    formatCurrency,
    truncate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    const profile = syslic.authentication.getProfile()

    const isLoading = true

    const finalValue = []
    const commissionPercentage = []
    const invoicingTable = []
    const invoicingList = []
    const checkedInvoicing = []
    const commissionNotes = []
    const noteEditable = true
    const noteId = ''

    const searchQuery = ''
    const currentPage = 1
    const perPage = 10
    const perPageOptions = [10, 25, 50, 100]
    const totalItems = 0
    const sortOptions = {
      sortBy: 'id',
      isSortDirDesc: true,
    }

    const selectAll = false

    const tableColumns = [
      {
        key: 'commitment__order__contract__bidding__client__name',
        label: 'Órgão',
        sortable: true,
        tdClass: 'py-1',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap',
      },
      {
        key: 'note_number',
        label: 'NF',
        sortable: true,
        tdClass: 'py-1',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap',
      },
      {
        key: 'delivery__invoicing_delivery_date',
        label: 'Data de Entrega',
        sortable: true,
        tdClass: 'py-1',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap',
      },
      {
        key: 'real_pay_date',
        label: 'Data de Pagamento ',
        sortable: true,
        tdClass: 'py-1',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap',
      },
      {
        key: 'delayed_days',
        label: 'Atraso',
        sortable: false,
        tdClass: 'py-1',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap',
      },
      {
        key: 'total_value',
        label: 'Valor Total',
        sortable: false,
        tdClass: 'py-1',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap',
      },
      {
        key: 'liquid_margin',
        label: 'Margem Líquida',
        sortable: false,
        tdClass: 'py-1',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap',
      },
      {
        key: 'commission_percentage',
        label: '% da Comissão',
        sortable: false,
        tdClass: 'py-1',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap commissionPercentageColumn',
      },
      {
        key: 'value',
        label: 'Comissão',
        sortable: false,
        tdClass: 'py-1',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap',
      },
      {
        key: 'status',
        label: 'Status',
        sortable: false,
        tdClass: 'py-1',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap',
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        visible: true,
        fixedColumn: true,
        thClass: 'text-nowrap biddingTableAction',
        tdClass: 'biddingTableAction py-1',
      },
    ]

    const options = {
      price: {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
      },
    }

    return {
      profile,
      isLoading,
      finalValue,
      commissionPercentage,
      invoicingTable,
      invoicingList,
      checkedInvoicing,
      commissionNotes,
      noteEditable,
      noteId,
      searchQuery,
      sortOptions,
      currentPage,
      totalItems,
      perPage,
      perPageOptions,
      selectAll,
      tableColumns,
      isChecked: false,
      options,
    }
  },
  computed: {
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
    hasPermissionToUpdate() {
      return this.profile.get_permissions_for_modules.commission.can_edit
    },
    hasPermissionToRead() {
      return this.profile.get_permissions_for_modules.commission.can_read
    },
  },
  watch: {
    currentPage: function updateCurrentPage() {
      this.fetchData()
    },
    'sortOptions.sortBy': function updateSortBy() {
      this.currentPage = 1
      this.fetchData()
    },
    'sortOptions.isSortDirDesc': function updateIsSortDirDesc() {
      this.currentPage = 1
      this.fetchData()
    },
    searchQuery: function updateQuery() {
      this.currentPage = 1
      this.fetchData()
    },
    perPage: function updatePerPage() {
      this.currentPage = 1
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    updateFilter(val) {
      this.currentPage = 1
      this.filter = val
      this.fetchData()
    },
    fetchData() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      this.isLoading = true
      const filterInvoicing = this.filter

      filterInvoicing.p = this.currentPage
      filterInvoicing.page_size = this.perPage

      if (this.sortOptions.isSortDirDesc) {
        filterInvoicing.ordering = `-${this.sortOptions.sortBy}`
      } else {
        filterInvoicing.ordering = this.sortOptions.sortBy
      }

      filterInvoicing.search = this.searchQuery

      syslic
        .commission
        .fetchCommissionsFromInvoicing(filterInvoicing)
        .then(response => {
          if (fetchPending === this.fetchId) {
            if (this.hasPermissionToUpdate || this.hasPermissionToRead) {
              this.invoicingTable = response.data.results
              this.totalItems = response.data.count
              this.createList(response.data.results)
            } else {
              this.invoicingTable = this.filterCommissionsView(response.data.results)
              this.totalItems = this.invoicingTable.length
              this.createList(this.invoicingTable)
            }
          }
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados das entregas concluídas.',
                text: 'Não foi possível ler os dados das entregas concluídas do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          }
        })
    },
    getStatus(commission, type) {
      const status = {
        name: 'Liberado',
        color: '#C8C6CE',
      }
      if (commission.length > 0) {
        commission.forEach(element => {
          if (element.owner_set.get_uuid === this.user.get_uuid) {
            if (element.status === 'pending') {
              status.name = 'Pendente'
              status.color = '#FC7033'
            } else if (element.status === 'payed') {
              status.name = 'Paga'
              status.color = '#55AF0F'
            } else {
              status.name = 'Liberado'
              status.color = '#C8C6CE'
            }
          }
        })
        return type === 'color' ? status.color : status.name
      }
      return type === 'color' ? status.color : status.name
    },
    async createList(invoicing) {
      invoicing.forEach(element => {
        this.invoicingList[element.id] = element
        this.checkedInvoicing[element.id] = false
      })

      await this.putCommissionValues(this.invoicingList)

      this.isLoading = false
    },
    putCommissionValues(invoicing) {
      return new Promise(resolve => {
        setTimeout(() => {
          invoicing.forEach(element => {
            this.invoicingList[element.id].valueDiscount = 0
            let hasCommission = false
            if (element.commission_set.length > 0) {
              element.commission_set.forEach(commission => {
                if (commission.owner_set.get_uuid === this.user.get_uuid) {
                  this.commissionPercentage[element.id] = commission.commission_percentage
                  this.commissionNotes[element.id] = commission.notes
                  hasCommission = true
                }
              })
            }
            if (!hasCommission) {
              this.commissionPercentage[element.id] = this.user.commission
            }
            this.updateFinalValue(element.id)
          })
          resolve()
        }, 200)
      })
    },
    updateFinalValue(index) {
      this.finalValue[index] = 0
      if (this.commissionPercentage[index]) {
        const num = (this.invoicingList[index].total_items) * (this.commissionPercentage[index] / 100)
        this.finalValue[index] = parseFloat(num.toString().replace(',', '.')).toFixed(2)
      }
    },
    storeCommissionValues(index, value, notes) {
      const invoicing = this.invoicingTable.find(item => item.id === index)
      this.$emit('store-unsaved', invoicing, value, notes)

      this.updateFinalValue(index)
      this.closeCommissionNotesModal()
    },
    checkboxChange(val) {
      if (this.checkedInvoicing[val.id]) {
        this.$emit('check-invoicing', val, this.commissionPercentage[val.id])
      } else {
        this.$emit('uncheck-invoicing', val)
      }
    },
    selectAllCheckbox(val) {
      if (val) {
        Object.keys(this.checkedInvoicing).forEach(key => {
          if (typeof this.checkedInvoicing[key] === 'boolean') {
            this.checkedInvoicing[key] = true
          }
        })
      } else {
        Object.keys(this.checkedInvoicing).forEach(key => {
          if (typeof this.checkedInvoicing[key] === 'boolean') {
            this.checkedInvoicing[key] = false
          }
        })
      }
    },
    clearChecks() {
      Object.keys(this.checkedInvoicing).forEach(key => {
        if (typeof this.checkedInvoicing[key] === 'boolean') {
          this.checkedInvoicing[key] = false
        }
      })
      this.selectAll = false
    },
    clearPercentages() {
      this.commissionPercentage = []
    },
    isCommissionEditable(commissions) {
      let editable = true

      if (commissions.length > 0) {
        commissions.forEach(commission => {
          if (commission.owner_set.get_uuid === this.user.get_uuid) {
            if (commission.status === 'payed' || commission.status === 'pending') {
              editable = false
            }
          }
        })
      }
      return editable
    },
    isNoteEditable(commissions) {
      let editable = true

      if (commissions.length > 0) {
        commissions.forEach(commission => {
          if (commission.owner_set.get_uuid === this.user.get_uuid) {
            if (commission.status === 'payed') {
              editable = false
            }
          }
        })
      }
      return editable
    },
    filterCommissionsView(invoicingObj) {
      const result = invoicingObj
      return result.filter(invoicing => !this.isCommissionEditable(invoicing.commission_set))
    },
    openCommissionNotesModal(invoicing) {
      this.noteEditable = true
      this.noteId = invoicing.id

      if (invoicing.commission_set.length > 0) {
        this.noteEditable = this.isNoteEditable(invoicing.commission_set)
      }

      setTimeout(() => {
        this.$refs.CommissionNotesModal.show()
      }, 100)
    },
    closeCommissionNotesModal() {
      this.$refs.CommissionNotesModal.hide()
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
      mdiPen,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.biddingTableView {
  border-radius: 5px;
}

.commissionPercentageColumn {
  min-width: 200px;
}

.sizeMin{
  min-width: 110px
}

.actionsColumn {
  .btn-icon {
    > svg {
      color: $primary;
    }
  }
}

.notesLabel {
  font-size: 1.2rem;
  font-weight: 500;
}

.noteBtn {
  min-width: 8rem;
}

.per-page-selector .vs__dropdown-toggle {
  max-height: 40.77px;
}

</style>
